import './style.scss';

function toggleBoxOpen() {
    const toggleButtons = document.getElementsByClassName('stbl-toggle__btn');

    if (!toggleButtons) return;

    [...toggleButtons].forEach(item => {
        item.addEventListener('click', function () {
            this.classList.toggle('toggle-open');
        });
    });
}
toggleBoxOpen();